<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <div class="brand-logo">
      <b-img
        style="object-fit: cover;width: 135px;"
        fluid
        :src="logoFull"
        alt="logo"
      />
    </div>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('maintenance.under_maintenance') }}
        </h2>

        <p class="mb-3">
          {{ $t('maintenance.text') }}
        </p>
        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Under maintenance page"
        />
      </div>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
// import { $themeConfig } from '@themeConfig'

export default {
  name: 'Maintenance',

  data() {
    return {
      downImg: require('@/assets/images/pages/old-under-maintenance.svg'),
      logoFull: require('@/assets/images/logo/logo.svg'),
    }
  },
  computed: {
    imgUrl() {
      return require('@/assets/images/pages/waitMFC.png')
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.downImg = require('@/assets/images/pages/old-under-maintenance-dark.svg')
      //   return this.downImg
      // }
      // return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
